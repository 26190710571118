#RenderZeroView {
    width: 100%;
    height: 350px;
    display: flex;
}

#RenderZeroViewInner {
    margin: auto;
    margin-top: -10px;
    text-align: center;
    font-size: 10px;
}

#imageData {
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 35px;
}

#viewForm {
    margin-bottom: 3px;
    width: 300px;
}

#viewForm input {
    font-size: 12px;
    width: 30px;
    margin-left: 0px !important;
    margin-top: 0px;
    background-color: black;
    color: white;
    border: 1px white;
    border-style: none none solid none;
    padding: 2px;
    font-family: 'Inconsolata', monospace;
}

#viewForm label {
    top: 1px;
    right: 2px;
}

#generatorWrapper {
    border: 1px dotted white;
    margin-bottom: 35px;
    width: 300px;
}

#generatorLabel {
    margin: 2px 0px 0px 0px;
}

#generatorData {
    margin: 1px 0px;
    max-width: 300px;
    height: 27px;
    overflow-wrap: break-word;
    overflow: scroll;
}

#RenderZeroViewInner span {
   display: inline; 
}

#trb {
    max-height: 20px;
    text-align: center;
}

#trb p {
    line-height: 0;
}

label {
    font-size: 12px;
    font-family: 'Inconsolata', monospace;
    position: relative;
}

#cornerOwner {
    position: relative;
    bottom: 48px;
    right: -255px;
}

#cornerOwnerEtherscan {
    color: white;
    font-size: 10px;
}