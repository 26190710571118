#RenderZeroGenerate {
    width: 100%;
    height: 350px;
    display: flex;
}

#RenderZeroGenerateInner {
    margin: auto;
    text-align: center;
    max-width: 400px;
}

form {
    margin-top: -30px;
}

input, textarea {
    margin: 3px 3px 3px 5px;
    font-size: 12px;
}

.innerForm {
    text-align: left;
    margin-top: 30px;
}

#inID {
    width: 30px;
    margin-left: 60px !important;
}

#inGV {
    width: 272px;
    height: 114px;
    margin-left: 10px !important;
    overflow: hidden;
    resize: none;
}

#buttonContainer {
    text-align: right;
}

#setButton {
    margin-right: 4px;
    background: none;
    border: 1px solid white;
    font-family: 'Inconsolata', monospace;
    color: white;
    line-height: 2;
    cursor: pointer;
}

.setButton:hover {
    transform: translateY(-3px);
}

#divTerms {
    padding-top: 5px;
    padding-bottom: 10px;
}

#laTerms a {
    color: white;
}

#inTerms {
    margin-left: 59px;
}

.textLinkGenerator {
    color: white;
    font-size: 10px;
}

.loaderTextContainer {
    margin: auto;
}

.generatorPauseText {
    color: red;
    background-color: white;
    padding: 3px 6px;
}

.generatorPauseText a {
    color: red;
    background-color: white;
}

.buttonDisabled {
    cursor: not-allowed !important;
    text-decoration: line-through;
}