.footer {
    background-color: white;
    width: 100vw;;
    font-family: 'Inconsolata';
    position: fixed;
    bottom: 0px;
    height: 50px;
}

.logo {
    padding: 3px;
}

.outlinks {
    height: 16px;
    padding: 25px 3px 3px 3px;
}

.separator {
    padding: 3px 8px 3px 8px;
}

.invert {
    filter: invert(100%);
}

ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
  }
li {
    display: flex;
    bottom: 0px;
  }


li:first-child {
    position: absolute;
    left: 5px;
}

li:last-child {
    position: absolute;
    right: 5px;
}

.footerTextLink {
    position: relative;
    bottom: 6px !important;
}

.featuredIcons {
    padding: 0px;
    height: 48px;
}

.featuredTable {
    bottom: 0px;
}

.featuredTable th {
    line-height: 0px;
    text-align: center;
}