.header {
    background-color: white;
    height: 70px;
    width: 100vw;;
    border-bottom: black 1px;
    font-family: 'Inconsolata';
}

.logo {
    padding: 3px;
}

ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
  }
li {
    display: flex;
  }


li:first-child {
    position: absolute;
    left: 3px;
}

li:last-child {
    position: absolute;
    right: 3px;
}